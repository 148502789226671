import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import axios from "axios";
import Header from "../../components/Header";
import EditCompany from "./EditCompany";
import EditHoliday from "./EditHoliday";
import AddHoliday from "./AddHoliday";
import DeleteHoliday from "./DeleteHoliday";
import Cookies from "universal-cookie";
import AddPaySched from "./AddPaySched";
import { dateFormatter } from "../../components/DtFormatter";
import EditPaySched from "./EditPaySched";
import { Edit, Camera } from "@material-ui/icons";
import UploadHoliday from "./UploadHoliday";
import ExporHolidayTemp from "./HolidayTempExport";
import EditLogo from "./EditLogo";
import EditSetting from "./EditSetting";

const pay_period = [
  "Weekly",
  "15th & 30th (Semi-Monthly)",
  "15th (Monthly)",
  "30th (Monthly)",
];
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

class CompanyDetail extends Component {
  state = {
    permissions: cookies.get("permissions")["master_data"],
    setting: cookies.get("setting"),
    companyDetails: [],
    holidayDetails: [],
    paySchedDetails: [],
    updateOccured: false,
    schemaDD: [],
    schedCurrentPage: 1,
    schedRecordsPerPage: 12,
  };
  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getDetails();
    axios.get("api/schemaDD").then((res) => {
      this.setState({ schemaDD: res.data });
    });
  }

  getDetails = () => {
    const id = this.props.match.params.id;
    axios.get("api/company/" + id).then((res) => {
      this.setState({ companyDetails: res.data });
    });

    axios.get("api/holiday/" + id).then((res) => {
      this.setState({ holidayDetails: res.data });
    });

    axios.get("api/paysched_list/" + id).then((res) => {
      this.setState({ paySchedDetails: res.data });
    });
  };

  handleBack() {
    this.props.history.goBack();
  }

  handleCallback = (cb) => {
    if (cb) {
      this.getDetails();
      this.setState({ updateOccured: true });
    }
  };

  componentDidUpdate() {}

  render() {
    const schedPages = [];
    for (
      let i = 1;
      i <=
      Math.ceil(
        this.state.paySchedDetails.length / this.state.schedRecordsPerPage
      );
      i++
    ) {
      schedPages.push(i);
    }

    const startIndex =
      this.state.schedCurrentPage * this.state.schedRecordsPerPage -
      this.state.schedRecordsPerPage;
    const endIndex = startIndex + this.state.schedRecordsPerPage;
    const paySchedData = this.state.paySchedDetails.slice(startIndex, endIndex);

    return (
      <div className="hk-wrapper hk-vertical-nav">
        <div class="pg-hk-wrapper">
          <Header />
          <div className="mt-20 hk-pg-wrapper">
            {this.state.permissions > 0 ? (
              <div className="container-fluid">
                <div className="row mb-10">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Company Information View</h3>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="btn-group float-right">
                      {/* <button
                        className="btn btn-sm btn-danger float-right "
                        onClick={this.handleBack}
                      >
                        Back to Previous Page
                      </button> */}
                      <Link
                        to={{
                          pathname: `/company`,
                          query: `/company`,
                          search:
                            "?search=" +
                            this.props.location.search.split("=").pop(),
                        }}
                        className="btn btn-sm btn-danger float-right "
                        title={"View Transaction Details"}
                      >
                        Back to Previous Page
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5>Company Details</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools">
                          {this.state.permissions > 2 ? (
                            <>
                              <EditCompany
                                schemaDD={this.state.schemaDD}
                                companyDetail={this.state.companyDetails}
                                parentCallback={this.handleCallback}
                              />
                              <EditSetting
                                companyId={this.state.companyDetails.id}
                                companyDetail={this.state.companyDetails}
                                parentCallback={this.handleCallback}
                              />
                              <EditLogo
                                companyId={this.state.companyDetails.id}
                                parentCallback={this.handleCallback}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="card-body">
                      <div className="row mb-10">
                          <div className="col-xl-12 col-sm-12">
                            <h6 className="hk-sec-title">General Info</h6>
                          </div>
                        </div>
                        <div className="row mb-10 ml-10">
                          <div className="col-xl-2 col-sm-10">
                            <strong>Logo</strong>
                          </div>
                          <div className="col-xl-4 col-sm-2 align-top">
                            :&nbsp;&nbsp;&nbsp;
                            <div className="avatar">
                              {this.state.companyDetails.logo_file_name ? (
                                <img
                                  src={
                                    process.env.REACT_APP_API_LINK +
                                    "companyLogos/" +
                                    this.state.companyDetails.logo_file_name
                                  }
                                  alt="user"
                                  className="avatar-img rounded-circle"
                                />
                              ) : (
                                <img
                                  src="dist/img/avatar12.jpg"
                                  alt="user"
                                  className="avatar-img rounded-circle"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-10 ml-10">
                          <div className="col-xl-2 col-sm-10">
                            <strong>Code</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.code}
                          </div>
                          <div className="col-xl-2 col-sm-10">
                            <strong>Status</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            <strong
                              className={
                                this.state.companyDetails.status == "ACTIVE"
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {this.state.companyDetails.status}
                            </strong>
                          </div>
                        </div>
                        <div className="row mb-10 ml-10">
                          <div className="col-xl-2 col-sm-10">
                            <strong>Name</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.name}
                          </div>
                          <div className="col-xl-2 col-sm-10">
                            <strong>Basic Schema</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.bschema_name}
                          </div>
                        </div>
                        <div className="row mb-10 ml-10">
                          <div className="col-xl-2 col-sm-10">
                            <strong>Payroll Cycle</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {pay_period[this.state.companyDetails.pay_period]}
                          </div>
                          <div className="col-xl-2 col-sm-10">
                            <strong>Overtime Schema</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.schema_name}
                          </div>
                        </div>
                        <div className="row mb-10 ml-10">
                          <div className="col-xl-2 col-sm-10">
                            <strong>Night Differentials</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.f_night_diff_in +
                              " - " +
                              this.state.companyDetails.f_night_diff_out}
                          </div>
                          <div className="col-xl-2 col-sm-10">
                            <strong>Address Line 1</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.address_1}
                          </div>
                        </div>
                        <div className="row mb-10 ml-10">
                          <div className="col-xl-2 col-sm-10">
                            <strong>Contact</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.contact_no}
                          </div>
                          <div className="col-xl-2 col-sm-10">
                            <strong>Address Line 2</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.address_2}
                          </div>
                        </div>
                        <div className="row mb-10 ml-10">
                          <div className="col-xl-2 col-sm-10">
                            <strong>Minimum Overtime(Hour)</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.min_overtime}
                          </div>
                        </div>
                        <hr />
                        <div className="row mb-10">
                          <div className="col-xl-12 col-sm-12">
                            <h6 className="hk-sec-title">Special Settings</h6>
                          </div>
                        </div>
                        
                        <div className="row mb-10 ml-10">
                          <div className="col-xl-2 col-sm-10">
                            <strong>Fixed Mandatory Contribution based on Monthly Basic</strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.mandatory_fixed_f}
                          </div>
                          <div className="col-xl-2 col-sm-10">
                            <strong>
                            Special Run - 13th Month w/o Withholding Tax
                            </strong>
                          </div>
                          <div className="col-xl-4 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.companyDetails.t_month_tax_f}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5>Payroll Schedule</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools">
                          {this.state.permissions > 1 ? (
                            <AddPaySched
                              companyDetail={this.state.companyDetails}
                              parentCallback={this.handleCallback}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div class="table-responsive mb-10">
                          <table class="table table-sm table-bordered table-striped mb-0">
                            <thead className="thead-secondary">
                              <tr className="text-center">
                                <th>Pay Period ID</th>
                                <th>Year</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Payment Date</th>
                                <th>Status</th>
                                <th style={{ width: "110px" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {paySchedData.map((paySched) => (
                                <tr key={paySched.id}>
                                  <td>{paySched.code}</td>
                                  <td>{paySched.sched_year}</td>
                                  <td>
                                    {dateFormatter(
                                      paySched.start_date,
                                      "MMM dd, yyyy"
                                    )}
                                  </td>
                                  <td>
                                    {dateFormatter(
                                      paySched.end_date,
                                      "MMM dd, yyyy"
                                    )}
                                  </td>
                                  <td>
                                    {dateFormatter(
                                      paySched.payment_date,
                                      "MMM dd, yyyy"
                                    )}
                                  </td>
                                  <td>
                                    {paySched.sched_status == 3 ? (
                                      <strong className="text-success">
                                        POSTED
                                      </strong>
                                    ) : paySched.sched_status == 2 ? (
                                      <strong className="text-info">
                                        FOR POSTING
                                      </strong>
                                    ) : paySched.sched_status == 1 ? (
                                      <strong className="text-warning">
                                        DRAFT
                                      </strong>
                                    ) : (
                                      <strong className="text-danger">
                                        OPEN
                                      </strong>
                                    )}
                                  </td>
                                  <td>
                                    {this.state.permissions > 1 ? (
                                      <EditPaySched
                                        parentCallback={this.handleCallback}
                                        paySchedDetails={paySched}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="drew-pagination">
                          {this.state.paySchedDetails.length > 0
                            ? "Showing " +
                              (startIndex + 1) +
                              " to " +
                              (endIndex > this.state.paySchedDetails.length
                                ? this.state.paySchedDetails.length
                                : endIndex) +
                              " of " +
                              this.state.paySchedDetails.length
                            : ""}

                          <ul class="pagination ml-2">
                            {schedPages.map((number) => (
                              <li
                                key={number}
                                id={number}
                                className={
                                  this.state.schedCurrentPage == number
                                    ? "paginate_button page-item active"
                                    : "paginate_button page-item"
                                }
                                onClick={() =>
                                  this.setState({
                                    schedCurrentPage: number,
                                  })
                                }
                              >
                                <a class="page-link">{number}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.setting.stand_alone > 0 ? (
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="card">
                        <div className="card-header card-header-action">
                          <h5>Company Holiday</h5>
                          <div className="d-flex align-items-center card-action-wrap-tools"></div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-10">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <ExporHolidayTemp
                                companyId={this.state.companyDetails.id}
                              />

                              {this.state.permissions > 1 ? (
                                <UploadHoliday
                                  companyId={this.state.companyDetails.id}
                                  parentCallback={this.handleCallback}
                                />
                              ) : (
                                ""
                              )}

                              {this.state.permissions > 1 ? (
                                <AddHoliday
                                  companyId={this.state.companyDetails.id}
                                  parentCallback={this.handleCallback}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div class="table-responsive mb-10">
                            <table class="table table-sm table-bordered table-striped mb-0">
                              <thead className="thead-secondary">
                                <tr className="text-center">
                                  <th>Type</th>
                                  <th>Name</th>
                                  <th>Date</th>
                                  <th>Last Update/Updated By</th>
                                  <th style={{ width: "110px" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.holidayDetails.map((holiday) => (
                                  <tr key={holiday.id}>
                                    <td>{holiday.holiday_type_name}</td>
                                    <td>{holiday.name}</td>
                                    <td>{holiday.f_holiday_date}</td>
                                    <td>
                                      {holiday.f_updated_at}
                                      <br />
                                      {holiday.updated_by}
                                    </td>
                                    <td>
                                      <div className="btn-group">
                                        {this.state.permissions > 2 ? (
                                          <EditHoliday
                                            holidayDetail={holiday}
                                            parentCallback={this.handleCallback}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {this.state.permissions > 3 ? (
                                          <DeleteHoliday
                                            name={holiday.name}
                                            id={holiday.id}
                                            parentCallback={this.handleCallback}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                <h1 className="m-0 text-center">
                  Your credentials is not sufficient to access this:
                  <br />
                </h1>
                <br />
                <h2 className="text-center">
                  Module : Master Data
                  <br />
                  Access Type : {access[this.state.permissions]}
                  <br />
                  Please contact your Payroll Administrator.
                  <br />
                  <a className="btn btn-primary btn-sm" href="/#/app">
                    Back to Dashboard
                  </a>
                </h2>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(CompanyDetail);
